@font-face {
  font-family: "RubikRegular";
  src: url("./assets/fonts/Rubik-Regular.woff2") format("woff2"),
    url("./assets/fonts/Rubik-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: "RubikRegular";
}

body {
  background-color: #f2f2f2;
  overflow: hidden;
}

.contain {
  object-fit: contain;
}

.cover {
  object-fit: cover;
}

.w-fit {
  width: fit-content;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.custom-navbar {
  border-bottom: 12px #1664a5 solid;
}

.search-bar {
  border: 2px #1664a5 solid;
  border-radius: 8px;
  width: 420px;
  color: #1664a5;
  font-size: 16px;
  background-color: #e6e2e2;
}

.search-bar::placeholder {
  color: #1664a5;
  text-transform: uppercase;
  font-size: 14px;
}

.search-bar:focus {
  outline: none;
}

.search-button-prods {
  border: none;
  padding: 7px;
  background-color: transparent;
}

.menu-item {
  background-color: #a0a0a0;
  list-style: none;
  width: 33%;
  border-radius: 8px 8px 0 0;
  transition: all 0.5s;
}

.menu-item:hover {
  background-color: #1664a5;
}

.menu-item.active {
  background-color: #1664a5;
}

.menu-item a {
  text-decoration: none;
  color: white;
  width: 100%;
  font-size: 17px;
}

.menu-item a:hover {
  color: white;
}

.contact-title {
  color: #1664a5;
}

.contact-form label {
  text-transform: uppercase;
  color: #1664a5;
  padding-left: 6px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  color: #1664a5;
  border: 2.2px solid #1664a5;
  border-radius: 8px;
  background-color: #fff;
  padding: 4px 8px;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
}

.contact-phone input {
  border-radius: 0 8px 8px 0 !important;
}

.PhoneInputCountry {
  border: 2.2px solid #1664a5;
  border-right: none;
  border-radius: 8px 0 0 8px;
  margin: 0 !important;
  padding: 4px 10px;
  background-color: #fff;
}

.contact-error-text {
  color: #c20323;
  font-style: italic;
}

.contact-button {
  color: #fff;
  border: 2.2px solid #1664a5;
  border-radius: 8px;
  background-color: #1664a5;
  text-transform: uppercase;
  transition: all 0.3s;
}

.contact-button:hover {
  color: #1664a5;
  background-color: #fff;
}

.contact-button:disabled {
  opacity: 0.5;
}

.success-message {
  font-style: italic;
  color: #1664a5;
}

.contact-img {
  border-left: 12px solid #1664a5;
}

.contact-img img {
  filter: grayscale(0.6) opacity(0.5);
}

.menu-title-wrapper-mob {
  background-color: #d8d8d8;
}

.menu-title-mob {
  color: #1664a5;
  font-weight: bold;
}

#nav-icon {
  width: 30px;
  height: 20px;
  position: relative;
  margin: auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 8;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 3.6px;
  width: 100%;
  background: #1664a5;
  border-radius: 6px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 0px;
}

#nav-icon span:nth-child(2),
#nav-icon span:nth-child(3) {
  top: 8px;
}

#nav-icon span:nth-child(4) {
  top: 16px;
}

#nav-icon.open span:nth-child(1) {
  top: 8px;
  width: 0%;
  left: 50%;
}

#nav-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(4) {
  top: 8px;
  width: 0%;
  left: 50%;
}

.menu-wrapper-mob {
  position: absolute;
  top: 50px;
  right: 0;
  width: fit-content;
  opacity: 0;
  margin-right: 3px;
  transition: all 0.8s;
  z-index: 10;
  pointer-events: none;
}

.menu-wrapper-mob.show {
  opacity: 1;
  pointer-events: all;
}

.menu-dropdown {
  width: fit-content !important;
  box-shadow: #6c757d 0px 3px 6px -2px;
}

.menu-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #d8d8d8;
  padding: 36px 36px 28px 36px;
}

.menu-dropdown li a {
  text-decoration: none;
  color: #1664a5;
  text-transform: uppercase;
  font-weight: bold;
}

.mobnav-active {
  color: #d81212 !important;
}

.menu-pointer {
  margin-left: auto;
  width: 40px;
  height: 30px;
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
  background-color: #d8d8d8;
}

.ratio-logo {
  --bs-aspect-ratio: 80%;
  /* width: 180px; */
}

.logo-max-w-header {
  max-width: 170px;
}

.logo-header-mob {
  width: 110px;
}

.footer {
  border-top: #1664a5 solid 10px;
  background-color: #d8d8d8;
}

.logo-footer {
  border-right: 3px solid #1664a5;
}

.footer-copyrights p {
  font-size: 16px;
  color: #1664a5;
  margin: 0;
}

.logo-max-w-footer {
  max-width: 150px;
}

.w-25 {
  width: 25%;
}

.home-slider .swiper-button-next:after {
  content: "";
}

.home-slider .swiper-button-next {
  background-image: url(./assets/images/carousel-arrow.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: scale(-1) translateY(50%);
  width: 120px;
  height: 120px;
  margin-top: 0 !important;
}

.home-slider .swiper-button-prev:after {
  content: "";
}

.menu-item-mob {
  transform: translateY(0);
  transition: all 0.4s;
  width: 38px;
}

.menu-item-mob:hover {
  transform: translateY(-3px);
}

.home-slider .swiper-button-prev {
  background-image: url(./assets/images/carousel-arrow.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 120px;
  height: 120px;
  transform: translateY(-50%);
  margin-top: 0 !important;
}

.ratio-slider-home {
  --bs-aspect-ratio: 32%;
}

.menu-margin {
  margin-top: -15px;
}

.bullets-container {
  background-color: #1664a5;
}

.home-banner {
  border-top: none;
}

.home-bullets .swiper-pagination-bullet {
  background-color: #fff !important;
  opacity: 1 !important;
  width: 10px;
  height: 10px;
  box-shadow: #000 1px 1px 3px 0px;
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.home-bullets .swiper-pagination-bullet-active {
  background-color: #a0a0a0 !important;
}

.home-item {
  border-left: 4px solid #d81212;
}

.home-item p {
  font-size: 22px;
  color: #1664a5;
}

.home-item-description {
  background-color: #d8d8d8;
  box-shadow: #6c757d 0px 3px 6px -2px;
  color: #1664a5;
}

.rounded-corners {
  border-radius: 15px;
  overflow: hidden;
}

/* width */
.custom-scroll::-webkit-scrollbar {
  width: 15px;
  display: block;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
  background: #b1d1e8;
  /* display: block; */
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  /* background: #888; */
  background-image: url(./assets/images/scroll-down-arrow.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: #1664a5;
  display: block;
}

/* Handle on hover */
/* .custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #144f80;
} */

.front {
  z-index: 999;
}

.zindex-10 {
  z-index: 10;
}

.lightgray-bg {
  background-color: #f2f2f2;
}

.pointer {
  cursor: pointer;
}

.location-icon-about {
  width: 100px;
}

.location-text-about {
  font-size: 20px;
}

.about-profile-wrapper {
  background-color: #d8d8d8;
  border-radius: 10px;
  overflow: hidden;
}

.about-profile-title {
  background-color: #1664a5;
}

.about-profile-title h3 {
  font-size: 28px;
  color: #f2f2f2;
}

.about-profile-content {
  color: #1664a5;
  max-height: 0;
  padding: 0;
  transition: max-height 0.5s;
}

.about-profile-content * {
  margin: 0;
}

.search-button-w {
  width: 35px;
}

.section-title-prod {
  color: #1664a5;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 32px;
}

.image-title-prod {
  background-color: #1664a5;
  border-radius: 15px 15px 0 0;
}

.product-title-prod {
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  color: #fff;
  margin: auto;
}

.product-description-prod {
  background-color: #fff;
  transition: all 0.5s;
  will-change: backgroundcolor;
  min-height: 120px;
}

.product-description-prod p {
  color: #1664a5;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0;
}

.single-prod-link {
  transform: scale(1) translateY(0) translateZ(0);
  transition: all 0.6s;
  filter: blur(0);
  will-change: transform;
}

.single-prod-link:hover {
  transform: scale(1.02) translateY(-3px) translateZ(0);
  filter: blur(0);
}

.single-prod-link:hover .product-description-prod {
  background-color: #d8d8d8;
}

.title-singprod {
  color: #1664a5;
  font-size: 32px;
  font-weight: bold;
}

/* animated arrow */

.arrow-icon {
  background-color: transparent;
  height: 36px;
  width: 29px;
  display: block;
  padding: 0.5em;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
}

.left-bar {
  position: absolute;
  background-color: transparent;
  top: 50%;
  left: 0;
  width: 20px;
  height: 5px;
  display: block;
  transform: rotate(40deg) translateY(50%);
  border-radius: 2px;
}

.left-bar:after {
  content: "";
  background-color: white;
  width: 20px;
  height: 5px;
  display: block;
  float: right;
  border-radius: 6px 10px 10px 6px;
  transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
  z-index: -1;
}

.right-bar {
  position: absolute;
  background-color: transparent;
  top: 50%;
  right: 0;
  width: 20px;
  height: 5px;
  display: block;
  transform: rotate(-40deg) translateY(50%);
  float: right;
  border-radius: 2px;
}

.right-bar:after {
  content: "";
  background-color: white;
  width: 20px;
  height: 5px;
  display: block;
  float: right;
  border-radius: 10px 6px 6px 10px;
  transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
  z-index: -1;
}

.open .left-bar:after {
  transform-origin: center center;
  transform: rotate(-70deg);
}
.open .right-bar:after {
  transform-origin: center center;
  transform: rotate(70deg);
}

.singprod-next-button {
  position: absolute;
  top: 38%;
  right: -10px;
  background-image: url(./assets/images/carousel-arrow.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: scale(-1);
  width: 50px;
  height: 50px;
  margin-top: 0 !important;
  cursor: pointer;
}

.singprod-prev-button {
  position: absolute;
  top: 38%;
  left: -10px;
  background-image: url(./assets/images/carousel-arrow.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
  margin-top: 0 !important;
  cursor: pointer;
}

.singprod-nav-buttons .swiper-button-disabled {
  opacity: 0.4;
}

.singprod-nav-buttons .swiper-button-disabled {
  opacity: 0.4;
}

.singprod-slide-wrapper {
  background-color: #1664a5;
  border-radius: 15px;
}

.singprod-slide-wrapper img {
  border-radius: 15px;
}

.singprod-bullets .swiper-pagination-bullet {
  background-color: #fff !important;
  opacity: 1 !important;
  width: 10px;
  height: 10px;
  box-shadow: #000 1px 1px 3px 0px;
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.singprod-bullets .swiper-pagination-bullet-active {
  background-color: #1664a5 !important;
}

.singprod-bullets-container {
  background-color: transparent;
}

.description-singprod {
  font-size: 18px;
  color: #1664a5;
  text-transform: uppercase;
}

/* Loading gears */
#wrapper {
  width: 100%;
  max-width: 725px;
  margin: 0px auto;
}

.gears {
  /* width: 48%; */
  display: inline-block;
}
.gears-container {
  width: 150px;
  height: 150px;
  font-size: 24px;
  padding: 9%;
  position: relative;
  margin: 0px auto;
}

.gear-rotate {
  width: 2em;
  height: 2em;
  top: 42%;
  left: 35%;
  margin-top: -1em;
  margin-left: -1em;
  background: #1664a5;
  position: absolute;
  border-radius: 1em;
  -webkit-animation: 1.2s gear-rotate linear infinite;
  -moz-animation: 1.2s gear-rotate linear infinite;
  animation: 1.2s gear-rotate linear infinite;
}
.gear-rotate-left {
  margin-top: -2.2em;
  top: 81%;
  width: 2em;
  right: 15.2%;
  height: 2em;
  background: #1664a5;
  position: absolute;
  border-radius: 1em;
  animation: 1.2s gear-rotate-left linear infinite;
}

.gear-rotate::before,
.gear-rotate-left::before {
  width: 2.8em;
  height: 2.8em;
  background: -webkit-linear-gradient(
      0deg,
      transparent 39%,
      #1664a5 39%,
      #1664a5 61%,
      transparent 61%
    ),
    -webkit-linear-gradient(60deg, transparent 42%, #1664a5 42%, #1664a5 58%, transparent
          58%),
    -webkit-linear-gradient(120deg, transparent 42%, #1664a5 42%, #1664a5 58%, transparent
          58%);
  background: -moz-linear-gradient(
      0deg,
      transparent 39%,
      #1664a5 39%,
      #47ec19 61%,
      transparent 61%
    ),
    -moz-linear-gradient(60deg, transparent 42%, #1664a5 42%, #1664a5 58%, transparent
          58%),
    -moz-linear-gradient(120deg, transparent 42%, #1664a5 42%, #1664a5 58%, transparent
          58%);
  background: -o-linear-gradient(
      0deg,
      transparent 39%,
      #1664a5 39%,
      #1664a5 61%,
      transparent 61%
    ),
    -o-linear-gradient(60deg, transparent 42%, #1664a5 42%, #1664a5 58%, transparent
          58%),
    -o-linear-gradient(120deg, transparent 42%, #47ec19 42%, #1664a5 58%, transparent
          58%);
  background: -ms-linear-gradient(
      0deg,
      transparent 39%,
      #1664a5 39%,
      #1664a5 61%,
      transparent 61%
    ),
    -ms-linear-gradient(60deg, transparent 42%, #1664a5 42%, #1664a5 58%, transparent
          58%),
    -ms-linear-gradient(120deg, transparent 42%, #1664a5 42%, #1664a5 58%, transparent
          58%);
  background: linear-gradient(
      0deg,
      transparent 39%,
      #1664a5 39%,
      #1664a5 61%,
      transparent 61%
    ),
    linear-gradient(
      60deg,
      transparent 42%,
      #1664a5 42%,
      #1664a5 58%,
      transparent 58%
    ),
    linear-gradient(
      120deg,
      transparent 42%,
      #1664a5 42%,
      #1664a5 58%,
      transparent 58%
    );
  position: absolute;
  content: "";
  top: -0.4em;
  left: -0.4em;
  border-radius: 1.4em;
}
.gear-rotate::after,
.gear-rotate-left::after {
  width: 1em;
  height: 1em;
  background: #fff;
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5em;
}

/*
 * Keyframe Animations 
 */

@-webkit-keyframes gear-rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-180deg);
  }
}

@-moz-keyframes gear-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes gear-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

@-webkit-keyframes gear-rotate-left {
  0% {
    -webkit-transform: rotate(30deg);
  }
  100% {
    -webkit-transform: rotate(210deg);
  }
}

@-moz-keyframes gear-rotate-left {
  0% {
    -webkit-transform: rotate(30deg);
  }
  100% {
    -webkit-transform: rotate(210deg);
  }
}

@keyframes gear-rotate-left {
  0% {
    -webkit-transform: rotate(30deg);
  }
  100% {
    -webkit-transform: rotate(210deg);
  }
}

.pagination-container {
  list-style: none;
  display: flex;
  padding: 0;
}

.pagination-container li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  margin-right: 6px;
}

.pagination-container a {
  text-decoration: none;
  color: #1664a5 !important;
  padding: 3px 10px;
  margin-bottom: 0;
  width: 100%;
  height: 100%;
  transition: all 0.4s;
  border-radius: 8px;
}

.pagination-container a:hover {
  background-color: #1664a5;
  color: #f2f2f2 !important;
}

.pagination-previous {
  color: #f2f2f2;
}

.pagination-next {
  color: #f2f2f2;
}

.pagination-active {
  background-color: #1664a5;
  color: #f2f2f2 !important;
  border-radius: 8px !important;
}

.pagination-active a {
  color: #f2f2f2 !important;
}

.pagination-disabled {
  opacity: 0.4 !important;
}

.pagination-disabled a:hover {
  background-color: #f2f2f2 !important;
  color: #1664a5 !important;
  cursor: not-allowed;
}

/* Data loader */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #1664a5;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* button loader */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 72px;
  height: 8px;
}
.lds-ellipsis div {
  position: absolute;
  top: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #1664a5;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.language-switch {
  color: #1664a5;
  background-color: #fff;
  border-radius: 6px !important;
  border: 2px solid #1664a5;
  padding: 4px;
}

.language-switch:focus {
  outline: none;
}

.languages {
  list-style-type: none;
}

.languages li {
  border-right: 1px solid #1664a5;
  color: #1664a5;
  line-height: 14px;
  font-size: 14px;
  cursor: pointer;
}

.languages li:hover {
  color: #d81212;
}

.languages li.active {
  /* text-decoration: underline; */
  color: #d81212 !important;
}

.languages li:last-child {
  border-right: none;
}

.image-zoom figure {
  border-radius: 15px;
}

.image-zoom div,
.image-zoom div img:first-child {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}

.singprod-slider-thumbs .swiper-slide {
  filter: brightness(60%);
  background-color: #1664a5;
  cursor: pointer;
}

.singprod-slider-thumbs .swiper-slide-thumb-active {
  filter: brightness(100%);
  border: 3px solid #1664a5;
}

.centered-slides .swiper-wrapper {
  justify-content: center;
}

@media (max-width: 991.95px) {
  .contact-button:hover {
    color: #fff;
    background-color: #1664a5;
  }
  .contact-img {
    border-radius: 4px;
    border-left: none;
    border-top: 12px solid #1664a5;
  }
  .menu-item-mob:hover {
    transform: translateY(0);
  }
  .footer-copyrights p {
    font-size: 14px;
  }
  .ratio-slider-home {
    --bs-aspect-ratio: 45%;
  }
  .home-slider .swiper-button-prev,
  .home-slider .swiper-button-next {
    width: 100px;
    height: 100px;
  }
  .home-banner {
    border-top: 36px solid #1664a5;
  }
  .single-prod-link:hover {
    transform: scale(1) translateY(0);
  }
  .single-prod-link:hover .product-description-prod {
    background-color: #fff;
  }
  .languages {
    padding: 8px !important;
  }
  .languages li:hover {
    color: #6c757d;
  }
  .languages li.active {
    color: #1664a5 !important;
  }
  .languages li {
    color: #6c757d;
    border-right: 1px solid #6c757d;
  }
  .about-profile-title h3 {
    font-size: 22px;
  }
}

@media (max-width: 767.9px) {
  .logo-footer {
    border-right: none;
  }
  .footer-copyrights {
    border-top: 5px solid #1664a5;
  }
  .ratio-slider-home {
    --bs-aspect-ratio: 60%;
  }
  .home-slider .swiper-button-prev,
  .home-slider .swiper-button-next {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 575.9px) {
  /* .ratio-slider-home {
    --bs-aspect-ratio: 90%;
  } */
  .home-slider .swiper-button-prev,
  .home-slider .swiper-button-next {
    width: 50px;
    height: 50px;
  }
}
